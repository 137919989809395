export const about = [
  {
    title: "Systems Engineer",
    duration: "For over 40 years",
    icon: "/assets/about/SysEng01.png",
  },
  {
    title: "Marquette University",
    duration: "Started Here",
    icon: "/assets/about/MarquetteAerial.png",
  },
  {
    title: "US Army",
    duration: "Detour Here",
    icon: "/assets/about/USflagArmy.png",
  },
  {
    title: "Culver-Stockton College",
    duration: "Completed Here",
    icon: "/assets/about/CulverStocktonAerial.png",
  },
];

    // duration: "Jan 1960 - Current",
    // duration: "Sept 1953 - Dec 1955",
    // duration: "Dec 1955 - Dec 1957",
    // duration: "Aug 1958 - May 1960",
