import { useState, useEffect } from "react";

import styles from "./Header.module.css";

import { navbar } from "../content/navbar";

export const Header = () => {
  const [mobileNavigation, setMobileNavigation] = useState(false);
  const [windowDimension, setWindowDimension] = useState(null);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

  window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowDimension <= 700;

  return (
    <section className={styles.wrapper} >
      <div className={styles.wrapper}>
        <div>
        <img className={styles.icon} src="/assets/JohPicAwc.png" alt="profile" />
        </div>
        <div>
        <h1 className={styles.logo}>Portfolio</h1>
        </div>
      </div>
      <div className={styles.navigation}>
      {!mobileNavigation && isMobile ? (
        <img
          className={styles.toggle}
           styles={{color:"green"}}
          onClick={() => {
            setMobileNavigation(true);
            document.body.style.overflow = "hidden";
            document.body.style.logo = "hidden";
          }}
          src="/assets/actions/hamburger.png"
          alt="menu"
        />
      ) : (
        <div className={styles.nava}>
          {navbar.map((item, index) => {
            return (
              <p style={{color:"green", Left:"0px", width:"50px"}}
                key={index}
                className={styles.item}
                onClick={() => {
                  document
                    .getElementById(item.title.toLowerCase())
                    // .scrollIntoView({ behavior: "smooth" });  // scrolling
                    .scrollIntoView();
                  setMobileNavigation(false);     // no scrolling
                  document.body.style.overflow = "auto";
                }}
              >
                {item.title}
              </p>
            );
          })}
        </div>
      )}
  </div>  
  </section>
  );
};