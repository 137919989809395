import React from "react";
import styles from "./Pdf.module.css";

// const PDF_FILE_URL = "Resume20170201.pdf";
const PDF_FILE_URL = "Resume20170201.pdf";

export const Pdf = () => {
	const onButtonClick = (url) => {
		fetch(url)
      .then((response) => response.blob())
      .then(blob => {
				const fileURL = window.URL.createObjectURL(new Blob([blob]));
        const fileName = url.split("/").pop();
        const aTag = document.createElement("a"); 
        aTag.href = fileURL;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
				aTag.click();
        aTag.remove();
			});
		};
	return (
		<section  className={styles.wrapper}>
    <div>
			<center style={{margins:"0", padding:"0",color:"midnightblue"}}>Resume` 
				<button onClick={()=>onButtonClick(PDF_FILE_URL)}>
					Download
				</button>
			</center>
		</div>
    </section>
	);
};

// export default Pdf;
