export const services = [
  {
    title: "Web Development",
    description:"Over the course of my career I have used many languages. "+
    "Currently I have been working in PHP, utilizing the Slim "+
    "and Laravel Frameworks along with raw PHP for the server side "+
    "and React.js and Angular for the client side. I created two "+ 
    "demo products to provide samples of my work.",
    icon: "/assets/services/webDevPic.png",
  },
  // {
  //   icon: "/assets/services/photography.png",
  //   title: "Systems Architecture",
  //   description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  // },
  {
    icon: "/assets/services/girlgolfer.png",
    title: "EzScore",
    description: " This demonstration application was developed using React.js"+
                 " for the front-end (Client) and PHP Slim Framework for the back-end"+
                 " (Server) API. The app keeps golf scores for up to 4 golfers. "+
                 "It provides scoring for Stableford and Stoke play.",
  },
  {
    icon: "/assets/services/postingImage.png",
    title: "John's Posts",
    description: " This demonstration application was developed using React.js"+
                 " for the front-end (Client) and PHP for the back-end"+
                 " (Server) API connected to a mysql database."+
                 "The application emulates a blog. You may display, add or "+
                 "delete posts. It can be enhanced by adding users, catagories and "+
                 "edit capabilites",
  },
];