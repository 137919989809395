import styles from "./Footer.module.css";

export const Footer = () => {
  return (
    <section className={styles.wrapper}>
    <footer style={{margin:"0 0"}} >
      <h1 className={styles.title}>John Sereno</h1>
      <p>5449 W. 152nd Terrace, Leawood Kansas, 66224</p>
      <p>(913) 530-4925 | johnsereno@yahoo.com</p>
      {/* <div className={styles.socials}>
        <img
          src="/assets/socials/twitter.png"
          alt="social-icon"
          className={styles.icon}
        />
        <img
          src="/assets/socials/linkedin.png"
          alt="social-icon"
          className={styles.icon}
        />
        <img
          src="/assets/socials/github.png"
          alt="social-icon"
          className={styles.icon}
        />
      </div> */}
    </footer>
    </section>
  );
};