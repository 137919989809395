import styles from "./Services.module.css";
import { services } from "../content/services";

export const Services = () => {

  return (
    <section className={styles.wrapper}>
      {services.map((service, index) => {
        return (
          <div className={styles.card} key={index}>
            <h2 className={styles.title}>{service.title}</h2>
        <img className={styles.icon} src={service.icon} alt="icon"/>    <p className={styles.description}>{service.description}</p>
            <p className={styles.title}>{(() => {
              switch (service.title) {
                case "EzScore": var ezbutton = 
                                <a className={styles.link} 
                                  href="https://ezscore.sereno.us" >
                                    EZSCORE DEMO
                                </a>;
                                return ezbutton ;
                case "John's Posts":  var postbutton = 
                                      <a className={styles.link} 
                                        href="https://posts.sereno.us">
                                          JOHNS POSTS DEMO
                                      </a>;
                                      return postbutton ;
                default:return;
              }
            })()}
        </p>

          </div>
        );
      })}
    </section>
  );
};