export const activities = [
  {
    title: "Community work",
    text: "Developed, Programmed and Contributed Equipment "+
    "to Metropoitan Organization to Counter Sexual Assault "+
    "(MOCSA) for an office management system",
  },
  {
    title: "Personnal Interests",
    text: "Private pilot, Supported local charitable golf tournaments",
  },
  {
    title: "Blood Bank Conference Attendee",
    text: "Attended several conferences promoting Donor Management Application",
  },
  {
    title: "IT Instructor for IBM",
    text: "Provided classroom instruction for AIX,LINUX operating systems and inbedded applicatons",
  },
];