import styles from "./Contacts.module.css";

export const Contacts = () => {
  return (
    <form
      className={styles.wrapper}
      target="_blank"
      action="https://formsubmit.co/johnsereno@yahoo.com"
      method="POST"
    >
      <div className={styles.inputs}>
        <input
          className={styles.input}
          type="text"
          name="name"
          placeholder="Your name"
          required
        />
        <br />
        <input
          className={styles.input}
          type="email"
          name="email"
          placeholder="Your email"
          required
        />
      </div>
      <div className={styles.textareas}>
        <textarea
          className={styles.textarea}
          type="text"
          name="message"
          placeholder="Your message"
          required
        />
        </div>
        <div style={{marginTop:"10px", marginBottom:"20px"}}>
        <button type="submit">
          {/* <img className={styles.icon} src="/assets/actions/send.png" alt="" /> */}
          <p> Send</p>
        </button>
      </div>
    </form>
  );
};