export const projects = [
  {
    title: "US Air Defense System",
    description: "Spent four years working on SAGE and BUIC."+
    " The Semi-Automatic Ground Environment air defense system for the United States,"+
    "  and the Back-Up Interceptor Control system designed as a backup/replacement to SAGE.",
    image: "/assets/projects/airdefense.png",
  },
  {
    title: "EFTS App",
    description: "Architected, Managed and help Develop first "+
    " ELECTRONIC FUNDS TRANSFER SWITCH \"EFTS\" for debit cards" + 
    " in Kansas City. This was also the first indelendently owned front end switch for financial transactions in the United States.",
    image: "/assets/projects/efts.png",
  },
  {
    title: "Blood Bank App",
    description: "Designed, developed, implemented first Blood Bank"+
    " Donor Management Application in the United States."+
    "This was an on-line service for multiple Blood Banks across the US.",
    image: "/assets/projects/BloodDonor_220606.png",
  },
  {
    // title: "Weather App",
    title: "Awc Weather App",
    description: "Managed and help convert the Aviation Weather Center's (AWC) "+
    "website from raw PHP to Zend Framwork. Designed and converted "+
    "IFFDP the International Fight Folder Document Program"+
    " to WebIFFDP,"+
    "  for web access."+
    "The AWC is one of only two world aviation forcasting centers,"+
    " dedicated to beng the trusted authority and leading innovator for "+
    " aviation weather information.",
    image: "/assets/projects/genericweathermaps.png",
  },
];